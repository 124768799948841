import ApiProduct from 'types/api/Product';
import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';
import Element from 'types/redux/Element';
import ListCollection from 'types/redux/ListCollection';

import Product, { Genders, ProductTypes } from 'models/Product';

import { ELEMENT_MARKUP, LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams extends ListParamsDefault {
    offerId?: string,
    gender?: string;
    categoryId?: string;
    category?: string; // todo: deprecated
    bodyPartGroupId?: string;
    locationSlug?: string;
    visibility?: string;
    search?: string;
    suggestedProductId?: string;
    suggestedOfferId?: string;
    specialPromo?: boolean;
    locationId?: string;
    type?: ProductTypes;
    isSpecial?: boolean;
    resource?: 'simple' | null;
    productId?: string;
    isBundlable?: boolean;
    orderProductId?: string;
    gotBodyParts?: boolean;
    ignoreValidScope?: boolean;
    bodyPartId?: string;
}

export const list = async (params?: ListParams): Promise<ListCollection<Product>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicProducts, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.Products];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: resource
            .elements
            .map((element: ApiProduct) => new Product(element)),
        meta: resource.meta,
    };
};

export interface SingleParams {
    slug: string;
    gender?: Genders;
}

export const single = async (params: SingleParams): Promise<Element<Product>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicProduct, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiProduct = data && data[ApiResources.Product];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...ELEMENT_MARKUP,
        isLoaded: true,
        data: new Product(resource),
    };
};
